@import "https://fonts.googleapis.com/css2?family=Monoton&family=Bebas+Neue&display=swap";
:root {
  --bg-color: #232349;
  --primary-color: var(--OGreen);
  --secondary-color: var(--FMauve);
  --JOGwhite: #e6e7e8;
  --OGreen: #3d9970;
  --FMauve: #9575cd;
  --white: #fff;
  --lightGrey: #d2d6dd;
  --grey: #6a737d;
  --darkGrey: #3f4144;
  --lightRed: #ff6970;
  --red: #f5002f;
  --darkRed: #800400;
  --lightBlue: #d9edfc;
  --blue: #0085ca;
  --darkBlue: #1b365d;
  --lightGreen: #b5e8bf;
  --green: #28a745;
  --darkGreen: #005e5d;
  --lightYellow: #f2f0a1;
  --yellow: #ffdc00;
  --darkYellow: #f1b434;
  --lightOrange: #ff9d6e;
  --orange: #ff9e1b;
  --darkOrange: #d45d00;
  --lightPink: #fadeff;
  --pink: #ffb3db;
  --darkPink: #a15a95;
  --lightPurple: #c7b1dd;
  --purple: #6f42c1;
  --darkPurple: #3c1053;
  --lightBrown: #d7c4b7;
  --brown: #99897c;
  --darkBrown: #4e3629;
  --black: #222;
  --error-color: var(--red);
  --warning-color: var(--yellow);
  --success-color: var(--green);
  --neonPink: #fa1afa;
  --neonYellow: #effb11;
  --neonBlue: #13fbfb;
  --neonGreen: #8cf622;
  --gradient: linear-gradient(135deg, var(--neonYellow), var(--neonPink), var(--neonBlue));
  --grid-maxWidth: 120rem;
  --grid-gutter: 2rem;
  --font-size: 1.6rem;
  --font-color: var(--JOGwhite);
  --font-family: -apple-system, "BlinkMacSystemFont", "Avenir", "Avenir Next", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --mono-font: monaco, "Consolas", "Lucida Console", monospace;
  --heading-font: "Bebas Neue", serif;
  --brand-font: "Monoton", sans-serif;
  --heading-color: var(--JOGwhite);
  --margin: 1.4rem;
  --mini-margin: 2px;
  --padding: 1rem;
  --border-width: 3px;
  --radius: 1.4rem;
  --tab-text-color: var(--fontcolor);
  --tab-bg--color: none;
  --tab-hover-color: var(--lightGrey);
  --tab-active-color: none;
  --tag-text-color: var(--fontcolor);
  --tag-bg--color: none;
  --tag-border-color: var(--lightGrey);
  --small-screen: 480px;
  --medium-screen: 720px;
  --big-screen: 1080px;
  --grid-columns: 3;
  --grid-min-width: 200px;
  --grid-gap: 20px;
}

html {
  box-sizing: border-box;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-size: 62.5%;
  line-height: 1.15;
}

*, :after, :before {
  box-sizing: inherit;
}

body {
  background-color: var(--bg-color);
  text-rendering: optimizespeed;
  line-height: 1.6;
  font-size: var(--font-size);
  color: var(--font-color);
  font-family: var(--font-family);
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

img, picture {
  border-radius: var(--radius);
  max-width: 100%;
  display: block;
}

p > img {
  float: left;
  margin: 0 var(--margin) var(--margin) 0;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  color: var(--secondary-color);
  text-decoration: none;
}

a:hover:not(.button) {
  color: var(--primary-color);
}

button {
  font-family: inherit;
}

p {
  margin-top: 0;
}

blockquote {
  background-color: var(--lightGrey);
  border-left: 3px solid var(--darkGrey);
  padding: 1.5rem 2rem;
}

dl dt {
  color: var(--prmary-color);
  font-size: 1.2em;
  font-weight: 700;
}

dl dd {
  margin-left: 0;
  font-style: italic;
}

hr {
  background-color: var(--lightGrey);
  margin: calc(2 * var(--margin)) 0;
  border: none;
  height: 1px;
}

code, kbd, pre, samp, tt {
  font-family: var(--mono-font);
}

code, kbd {
  white-space: pre-wrap;
  background-color: var(--darkGrey);
  color: var(--white);
  border-radius: 4px;
  padding: .2em .4em;
  font-size: 90%;
}

pre {
  background-color: var(--darkGrey);
  padding: 1rem;
  font-size: 1em;
  overflow-x: auto;
}

pre code {
  background: none;
  padding: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

fieldset {
  border: 1px solid var(--lightGrey);
}

iframe {
  border: 0;
}

.display-1, .display-2, .display-3 {
  font-family: var(--font-family);
  color: var(--heading-color);
  font-size: 8rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 6rem;
}

.display-3 {
  font-size: 4rem;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  font-family: var(--heading-font);
  color: var(--heading-color);
  margin: .3em 0 .1em;
}

.big-text, .h1, h1 {
  font-size: 3.6em;
}

.h2, h2 {
  font-size: 3.2em;
}

.h3, h3 {
  font-size: 2.6em;
}

.h4, h4 {
  font-size: 2.2em;
}

.h5, h5 {
  font-size: 1.8em;
}

.h6, h6 {
  font-size: 1.4em;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: 700;
}

table {
  border: 1px solid var(--lightGrey);
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  width: 100%;
}

table tr:nth-of-type(2n) {
  background-color: var(--lightGrey);
}

td, th {
  vertical-align: middle;
  border: 1px solid #d3d3d3;
  padding: 1.2rem .4rem;
}

thead {
  background: var(--primary-color);
  color: var(--white);
}

tfoot {
  background: var(--secondary-color);
  color: var(--white);
}

.button, [type="button"], [type="reset"], [type="submit"], button {
  padding: var(--padding);
  color: var(--white);
  background: var(--primary-color);
  font-size: var(--font-size);
  text-align: center;
  cursor: pointer;
  margin: var(--mini-margin);
  border: 1px solid #0000;
  border-radius: 4px;
  line-height: 1;
  text-decoration: none;
  transition: opacity .2s;
  display: inline-block;
  transform: scale(1);
}

.button.dark, .button.error, .button.primary, .button.secondary, .button.success, .button.warning, [type="submit"] {
  color: #fff;
  z-index: 1;
  background-color: var(--primary-color);
}

.button:hover, [type="button"]:hover, [type="reset"]:hover, [type="submit"]:hover, button:hover {
  opacity: .8;
}

.button + .button {
  margin-left: 1rem;
}

.button.outline, button.outline {
  border-color: var(--lightGrey);
  background-color: #0000;
}

.button.outline.primary, button.outline.primary {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.button.outline.secondary, button.outline.secondary {
  border-color: var(--grey);
  color: var(--grey);
}

.button.outline.dark, button.outline.dark {
  border-color: var(--darkGrey);
  color: var(--darkGrey);
}

a.button.outline, button.outline {
  background: none !important;
}

.button.clear, button.clear {
  color: var(--primary-color);
  background-color: #0000;
  border-color: #0000;
}

.button.icon, button.icon {
  align-items: center;
  display: inline-flex;
}

.button.icon > img, button.icon > img {
  margin-left: 2px;
}

.button.icon-only, button.icon-only {
  padding: 1rem;
}

.button:active:not(:disabled), [type="button"]:active:not(:disabled), [type="reset"]:active:not(:disabled), [type="submit"]:active:not(:disabled), button:active:not(:disabled) {
  transform: scale(.98);
}

.grid {
  gap: var(--grid-gap);
  padding: var(--grid-gap);
  grid-template-columns: 1fr;
  display: grid;
}

.grid.small-gap {
  --grid-gap: 10px;
}

.grid.big-gap {
  --grid-gap: 30px;
}

.grid.no-gap {
  --grid-gap: 0;
}

@media screen and (width >= 600px) {
  .grid {
    grid-template-columns: repeat(var(--grid-columns), minmax(var(--grid-min-width), 1fr));
  }

  .one.column.grid {
    --grid-columns: 1;
  }

  .two.column.grid {
    --grid-columns: 2;
  }

  .three.column.grid {
    --grid-columns: 3;
  }

  .four.column.grid {
    --grid-columns: 4;
  }

  .five.column.grid {
    --grid-columns: 5;
  }

  .six.column.grid {
    --grid-columns: 6;
  }

  .row-span-2 {
    grid-row: span 2;
  }

  .row-span-3 {
    grid-row: span 3;
  }

  .row-span-4 {
    grid-row: span 4;
  }

  .row-span-5 {
    grid-row: span 5;
  }

  .row-span-6 {
    grid-row: span 6;
  }

  .col-span-2 {
    grid-column: span 2;
  }

  .col-span-3 {
    grid-column: span 3;
  }

  .col-span-4 {
    grid-column: span 4;
  }

  .col-span-5 {
    grid-column: span 5;
  }

  .col-span-6 {
    grid-column: span 6;
  }

  .start-2 {
    grid-column-start: 2;
  }

  .start-2.col-span-2 {
    grid-column: 2 / span 2;
  }

  .start-2.col-span-3 {
    grid-column: 2 / span 3;
  }

  .start-2.col-span-4 {
    grid-column: 2 / span 4;
  }

  .start-2.col-span-5 {
    grid-column: 2 / span 5;
  }

  .start-3 {
    grid-column-start: 3;
  }

  .start-3.col-span-2 {
    grid-column: 3 / span 2;
  }

  .start-3.col-span-3 {
    grid-column: 3 / span 3;
  }

  .start-3.col-span-4 {
    grid-column: 3 / span 4;
  }

  .start-4 {
    grid-column-start: 4;
  }

  .start-4.col-span-2 {
    grid-column: 4 / span 2;
  }

  .start-4.col-span-3 {
    grid-column: 4 / span 3;
  }

  .start-5 {
    grid-column-start: 5;
  }

  .start-5.col-span-2 {
    grid-column: 5 / span 2;
  }

  .start-6 {
    grid-column-start: 6;
  }
}

fieldset {
  padding: .5rem 2rem;
}

legend {
  text-transform: uppercase;
  letter-spacing: .1rem;
  font-size: .8em;
}

input:not([type="checkbox"], [type="radio"], [type="submit"], [type="color"], [type="button"], [type="reset"]), select, textarea, textarea[type="text"] {
  padding: var(--padding);
  border-radius: var(--radius);
  border: 1px solid var(--lightGrey);
  width: 100%;
  font-family: inherit;
  font-size: 1em;
  transition: all .2s;
  display: block;
}

select {
  appearance: none;
  background: #f3f3f6 url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='60' height='40' fill='%23555'><polygon points='0,0 60,0 30,40'/></svg>") 100% / 1ex no-repeat content-box border-box;
}

button:disabled, button:disabled:hover, input:disabled, input:disabled:hover {
  opacity: .4;
  cursor: not-allowed;
}

.grouped {
  display: flex;
}

.grouped > :not(:last-child) {
  margin-right: 16px;
}

.grouped.gapless > * {
  border-radius: 0 !important;
  margin: 0 0 0 -1px !important;
}

.grouped.gapless > :first-child {
  border-radius: 4px 0 0 4px !important;
  margin: 0 !important;
}

.grouped.gapless > :last-child {
  border-radius: 0 4px 4px 0 !important;
}

input:not([type="checkbox"], [type="radio"], [type="submit"], [type="color"], [type="button"], [type="reset"], :disabled) {
  background: var(--lightGrey);
  color: var(--darkGrey);
  border-radius: var(--radius);
  margin: var(--margin) 0;
  max-width: 40rem;
}

input:not([type="checkbox"], [type="radio"], [type="submit"], [type="color"], [type="button"], [type="reset"], :disabled):hover, select:hover, textarea:hover, textarea[type="text"]:hover {
  border-color: var(--secondary-color);
}

input:not([type="checkbox"], [type="radio"], [type="submit"], [type="color"], [type="button"], [type="reset"]):focus, select:focus, textarea:focus, textarea[type="text"]:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 1px var(--primary-color);
  outline: 0;
}

input.error:not([type="checkbox"], [type="radio"], [type="submit"], [type="color"], [type="button"], [type="reset"]), textarea.error {
  border-color: var(--error-color);
}

input.success:not([type="checkbox"], [type="radio"], [type="submit"], [type="color"], [type="button"], [type="reset"]), textarea.success {
  border-color: var(--success-color);
}

input.warning:not([type="checkbox"], [type="radio"], [type="submit"], [type="color"], [type="button"], [type="reset"]), textarea.warning {
  border-color: var(--warning-color);
}

[type="checkbox"], [type="radio"] {
  width: 2rem;
  height: 1.6rem;
}

::placeholder {
  color: #bdbfc4;
}

label.error, label.success, label.warning {
  background: var(--error-color);
  color: var(--white);
  padding: var(--padding);
  border-radius: var(--radius);
}

label.success {
  background: var(--success-color);
}

label.warning {
  background: var(--warning-color);
}

.nav {
  align-items: stretch;
  min-height: 5rem;
  display: flex;
}

.nav img {
  max-height: 3rem;
}

.nav > .container {
  display: flex;
}

.nav-center, .nav-left, .nav-right {
  flex: 1;
  display: flex;
}

.nav-left {
  justify-content: flex-start;
}

.nav-right {
  justify-content: flex-end;
}

.nav-center {
  justify-content: center;
}

@media screen and (width <= 600px) {
  .nav {
    flex-direction: column;
  }

  .nav-center, .nav-left, .nav-right {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.nav .brand, .nav a {
  color: var(--secondary-color);
  align-items: center;
  padding: 1rem 2rem;
  text-decoration: none;
  display: flex;
}

.nav .active:not(.button), .nav [aria-current="page"]:not(.button) {
  color: var(--primary-color);
}

.nav .button {
  margin: auto 1rem;
}

.card {
  padding: var(--padding);
  border-radius: var(--radius);
  background: #ffffff4d;
  box-shadow: 0 1px 3px #0000004d;
}

.tabs {
  align-items: center;
  display: flex;
}

.tabs a {
  text-decoration: none;
}

.tabs > .dropdown > summary, .tabs > a {
  color: var(--darkGrey);
  border: 1px solid var(--lightGrey);
  border-left: none;
  border-bottom: 2px solid var(--lightGrey);
  text-align: center;
  flex: 0 auto;
  max-height: 3em;
  padding: 1rem 2rem;
}

.tabs > .dropdown > summary:first-child, .tabs > a:first-child {
  border-left: 1px solid var(--lightGrey);
}

.tabs > a:hover, .tabs > a[aria-current="page"] {
  opacity: 1;
  background: var(--tab-hover-color);
}

.tabs > a.active, .tabs > a[aria-current="page"] {
  background: var(--tab-active-color);
}

.tabs.full a {
  flex: auto;
}

.tabs.vertical {
  flex-direction: column;
}

.tabs.vertical > a {
  border-left: 1px solid var(--lightGrey);
  border-bottom: none;
  width: 100%;
}

.tabs.vertical > a:last-child {
  border-bottom: 1px solid var(--lightGrey);
}

.tag {
  border: 1px solid var(--tag-border-color);
  background: var(--tag-bg-color);
  color: var(--tag-text-color);
  margin: var(--mini-margin);
  border-radius: 5px;
  padding: .4rem;
  font-size: .8em;
  display: inline-block;
}

.float-end, .float-right {
  float: right !important;
}

.float-left, .float-start {
  float: left !important;
}

.full-screen {
  width: 100%;
  min-height: 100vh;
}

.full-width {
  width: 100% !important;
}

.three-quarter-width {
  width: 75% !important;
}

.half-width {
  width: 50% !important;
}

.third-width {
  width: 33.33% !important;
}

.quarter-width {
  width: 25% !important;
}

.align-vertical {
  align-items: center;
  display: flex;
}

.align-horizontal {
  justify-content: center;
  display: flex;
}

.align-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.align-right {
  align-items: right;
  justify-content: flex-end;
  display: flex;
}

.align-left {
  align-items: left;
  justify-content: flex-start;
  display: flex;
}

.inline {
  display: inline;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.absolute {
  position: absolute;
}

.fixed {
  width: 100%;
  position: fixed;
}

.padding {
  padding: var(--padding);
}

.padding-horizontal {
  padding: 0 var(--padding);
}

.padding-vertical {
  padding: var(--padding) 0;
}

.no-padding {
  padding: 0 !important;
}

.margin {
  margin: var(--margin);
}

.margin-horizontal {
  margin: 0 var(--margin);
}

.margin-vertical {
  margin: var(--margin) 0;
}

.no-margin {
  margin: 0 !important;
}

.pointer {
  cursor: pointer !important;
}

.rounded {
  border-radius: var(--radius);
}

.circle {
  border-radius: 100%;
}

.dropshadow {
  box-shadow: 3px 3px 8px #0006;
}

.clearfix, .float-end:after, .float-start:after, p:has(img):after {
  content: "";
  clear: both;
  display: table;
}

.hidden {
  display: none !important;
}

.muted {
  opacity: .7;
}

.no-bullet {
  list-style: none;
}

.glow {
  text-shadow: 0 0 6px;
}

@media screen and (width <= 400px) {
  .hide-xs {
    display: none !important;
  }
}

@media screen and (width <= 600px) {
  .hide-sm {
    display: none !important;
  }
}

@media screen and (width <= 900px) {
  .hide-md {
    display: none !important;
  }
}

@media screen and (width >= 1200px) {
  .hide-lg {
    display: none !important;
  }
}

@media print {
  .hide-print {
    display: none !important;
  }
}

.lightGrey {
  background-color: var(--lightGrey) !important;
}

.darkGrey {
  background-color: var(--darkGrey) !important;
}

.bg-error {
  background-color: var(--error-color) !important;
}

.bg-success {
  background-color: var(--success-color) !important;
}

.bg-warning {
  background-color: var(--warning-color) !important;
}

.primary {
  background: var(--primary-color) !important;
}

.secondary {
  background: var(--secondary-color) !important;
}

.background {
  background: var(--bg-color) !important;
}

.lightBlue {
  background: var(--lightBlue) !important;
}

.blue {
  background: var(--blue) !important;
}

.darkBlue {
  background: var(--darkBlue) !important;
}

.lightGreen {
  background: var(--lightGreen) !important;
}

.green {
  background: var(--green) !important;
}

.darkGreen {
  background: var(--darkGreen) !important;
}

.lightYellow {
  background: var(--lightYellow) !important;
}

.yellow {
  background: var(--yellow) !important;
}

.darkYellow {
  background: var(--darkYellow) !important;
}

.lightOrange {
  background: var(--lightOrange) !important;
}

.orange {
  background: var(--orange) !important;
}

.darkOrange {
  background: var(--darkOrange) !important;
}

.lightRed {
  background: var(--lightRed) !important;
}

.red {
  background: var(--red) !important;
}

.darkRed {
  background: var(--darkRed) !important;
}

.lightPurple {
  background: var(--lightPurple) !important;
}

.purple {
  background: var(--purple) !important;
}

.darkPurple {
  background: var(--darkPurple) !important;
}

.lightPink {
  background: var(--lightPink) !important;
}

.pink {
  background: var(--pink) !important;
}

.darkPink {
  background: var(--darkPink) !important;
}

.lightBrown {
  background: var(--lightBrown) !important;
}

.brown {
  background: var(--brown) !important;
}

.darkBrown {
  background: var(--darkBrown) !important;
}

.white {
  background: var(--white) !important;
}

.grey {
  background: var(--grey) !important;
}

.lightGrey {
  background: var(--lightGrey) !important;
}

.darkGrey {
  background: var(--darkGrey) !important;
}

.black {
  background: var(--black) !important;
}

.neonPink {
  background: var(--neonPink) !important;
}

.neonYellow {
  background: var(--neonYellow) !important;
}

.neonBlue {
  background: var(--neonBlue) !important;
}

.neonGreen {
  background: var(--neonGreen) !important;
}

.primary-text {
  color: var(--primary-color) !important;
}

.secondary-text {
  color: var(--secondary-color) !important;
}

.background-text {
  color: var(--bg-color) !important;
}

.lightBlue-text {
  color: var(--lightBlue) !important;
}

.blue-text {
  color: var(--blue) !important;
}

.darkBlue-text {
  color: var(--darkBlue) !important;
}

.lightGreen-text {
  color: var(--lightGreen) !important;
}

.green-text {
  color: var(--green) !important;
}

.darkGreen-text {
  color: var(--darkGreen) !important;
}

.lightYellow-text {
  color: var(--lightYellow) !important;
}

.yellow-text {
  color: var(--yellow) !important;
}

.darkYellow-text {
  color: var(--darkYellow) !important;
}

.lightOrange-text {
  color: var(--lightOrange) !important;
}

.orange-text {
  color: var(--orange) !important;
}

.darkOrange-text {
  color: var(--darkOrange) !important;
}

.lightRed-text {
  color: var(--lightRed) !important;
}

.red-text {
  color: var(--red) !important;
}

.darkRed-text {
  color: var(--darkRed) !important;
}

.lightPurple-text {
  color: var(--lightPurple) !important;
}

.purple-text {
  color: var(--purple) !important;
}

.darkPurple-text {
  color: var(--darkPurple) !important;
}

.lightPink-text {
  color: var(--lightPink) !important;
}

.pink-text {
  color: var(--pink) !important;
}

.darkPink-text {
  color: var(--darkPink) !important;
}

.lightbrown-text {
  color: var(--lightbrown) !important;
}

.brown-text {
  color: var(--brown) !important;
}

.darkBrown-text {
  color: var(--darkBrown) !important;
}

.grey-text {
  color: var(--grey) !important;
}

.lightGrey-text {
  color: var(--lightGrey) !important;
}

.darkGrey-text {
  color: var(--darkGrey) !important;
}

.black-text {
  color: var(--black) !important;
}

.white-text {
  color: var(--white) !important;
}

.error-text {
  color: var(--error-color) !important;
}

.success-text {
  color: var(--success-color) !important;
}

.warning-text {
  color: var(--warning-color) !important;
}

.neonPink-text {
  color: var(--neonPink) !important;
}

.neonBlue-text {
  color: var(--neonBlue) !important;
}

.neonYellow-text {
  color: var(--neonYellow) !important;
}

.neonGreen-text {
  color: var(--neonGreen) !important;
}

.JOG, .brand {
  font-family: var(--brand-font);
  letter-spacing: -.04em;
  color: var(--JOGwhite);
}

.J {
  color: var(--FMauve);
}

.G {
  color: var(--OGreen);
}

h1.JOG, h1.brand {
  font-size: 72px;
}

.gradient {
  background: var(--gradient);
}

.gradient-text {
  background: var(--gradient);
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  background-size: 100%;
  background-clip: text;
  display: inline-block;
}

.thick {
  border-width: calc(var(--border-width) * 2) !important;
}

.thin {
  border-width: calc(var(--border-width) / 2) !important;
}

.no-border {
  border: none;
}

.primary-border {
  border: var(--border-width) solid var(--primary-color) !important;
}

.secondary-border {
  border: var(--border-width) solid var(--secondary-color) !important;
}

.lightGrey-border {
  border: var(--border-width) solid var(--lightGrey) !important;
}

.darkGrey-border {
  border: var(--border-width) solid var(--darkGrey) !important;
}

.grey-border {
  border: var(--border-width) solid var(--grey) !important;
}

.error-border {
  border: var(--border-width) solid var(--error-color) !important;
}

.success-border {
  border: var(--border-width) solid var(--success-color) !important;
}

.warning-border {
  border: var(--border-width) solid var(--warning-color) !important;
}

.lightRed-border {
  border: var(--border-width) solid var(--lightRed) !important;
}

.red-border {
  border: var(--border-width) solid var(--red) !important;
}

.darkRed-border {
  border: var(--border-width) solid var(--darkRed) !important;
}

.lightGreen-border {
  border: var(--border-width) solid var(--lightGreen) !important;
}

.green-border {
  border: var(--border-width) solid var(--green) !important;
}

.darkGreen-border {
  border: var(--border-width) solid var(--darkGreen) !important;
}

.lightBlue-border {
  border: var(--border-width) solid var(--lightBlue) !important;
}

.blue-border {
  border: var(--border-width) solid var(--blue) !important;
}

.darkBlue-border {
  border: var(--border-width) solid var(--darkBlue) !important;
}

.lightYellow-border {
  border: var(--border-width) solid var(--lightYellow) !important;
}

.yellow-border {
  border: var(--border-width) solid var(--yellow) !important;
}

.darkYellow-border {
  border: var(--border-width) solid var(--darkYellow) !important;
}

.lightOrange-border {
  border: var(--border-width) solid var(--lightOrange) !important;
}

.orange-border {
  border: var(--border-width) solid var(--orange) !important;
}

.darkOrange-border {
  border: var(--border-width) solid var(--darkOrange) !important;
}

.lightPink-border {
  border: var(--border-width) solid var(--lightPink) !important;
}

.pink-border {
  border: var(--border-width) solid var(--pink) !important;
}

.darkPink-border {
  border: var(--border-width) solid var(--darkPink) !important;
}

.lightPurple-border {
  border: var(--border-width) solid var(--lightPurple) !important;
}

.purple-border {
  border: var(--border-width) solid var(--purple) !important;
}

.darkPurple-border {
  border: var(--border-width) solid var(--darkPurple) !important;
}

.lightBrown-border {
  border: var(--border-width) solid var(--lightBrown) !important;
}

.brown-border {
  border: var(--border-width) solid var(--brown) !important;
}

.darkBrown-border {
  border: var(--border-width) solid var(--darkBrown) !important;
}

.neonPink-border {
  border: var(--border-width) solid var(--neonPink) !important;
}

.neonBlue-border {
  border: var(--border-width) solid var(--neonBlue) !important;
}

.neonYellow-border {
  border: var(--border-width) solid var(--neonYellow) !important;
}

.neonGreen-border {
  border: var(--border-width) solid var(--neonGreen) !important;
}
/*# sourceMappingURL=index.8cf4454d.css.map */
